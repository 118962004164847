body { background-color: #ffffff; }
body { color: var(--clr-11876); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-11876);
font-family: 'Raleway';
font-weight: 800;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-11876);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-11876);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-11876);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-11876);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-11876);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-11876);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
body {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-11877);}
/* Primary:3 */
.MES3 {
background-color: #000000;
color: #ffffff;
 }
/* Primary:4 */
.MES4 {
background-color: #000000;
color: #ffffff;
 }
.MES4 {
background-color: #000000;
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
a.MEC4 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: transparent;
color: transparent;
 }
/* Secondary:6 */
.MES6 {
background-color: transparent;
color: transparent;
 }
.MES6 {
background-color: transparent;
color: transparent;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: transparent;
 }
 }
cite.MEC6{
color: transparent;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-11876);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-11878);
color: #ffffff;
 }
.MES8 {
background-color: var(--clr-11878);
color: #ffffff;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
a.MEC8 { color: var(--clr-11879);
&:hover { color: transparent;}
 }
cite.MEC8{
color: #ffffff;
}
/* Light:9 */
.MES9 {
background-color: var(--clr-11879);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-11879);
 }
.MES10 {
background-color: var(--clr-11879);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-11880);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 5px 30px;

 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-11880);
 }
.MES12 {
background-color: var(--clr-11880);
 }
/* Menu:13 */
.MES13 {
background-color: var(--clr-11881);
color: #ffffff;
font-size: 14px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
 }
.MES13 {
background-color: var(--clr-11881);
color: #ffffff;
font-size: 14px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
a.MEC13 { color: #ffffff;
&:hover { color: var(--clr-11879);}
 }
cite.MEC13{
color: #ffffff;
font-size: 14px;
}
/* Upcoming Classes Panel:14 */
.MES14 {
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-11879);
 }
.MES14 {
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-11879);
 }
/* Teacher Panel:15 */
.MES15 {
background-color: var(--clr-11882);
padding: 15px;

@media #{$medium-up} {
padding: 30px 15px;

}
 }
.MES15 {
background-color: var(--clr-11882);
padding: 15px;

@media #{$medium-up} {
padding: 30px 15px;

}
 }
/* Hollaw:16 */
.MES16 {
color: #ffffff;
&:hover { color: #ffffff;}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* :17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-11876);
font-size: 14px;
 }
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-11876);
font-size: 14px;
 }
a.MEC18 { color: var(--clr-11876);
&:hover { color: var(--clr-11876);}
 }
cite.MEC18{
color: var(--clr-11876);
font-size: 14px;
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #ffffff;
 }
h1.MEC19 { @media #{$medium-up} { font-size: 44px; }; }
h1.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC19 { @media #{$medium-up} { font-size: 38.5px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC19 { @media #{$medium-up} { font-size: 33px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC19 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC19 { @media #{$medium-up} { font-size: 19.8px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 21.6px; }; }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-11878);
color: #ffffff;
font-size: 11.2px;
 }
.MES20 {
background-color: var(--clr-11878);
color: #ffffff;
font-size: 11.2px;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
a.MEC20 { color: var(--clr-11879);
&:hover { color: var(--clr-11879);}
 }
cite.MEC20{
color: #ffffff;
font-size: 11.2px;
}
/* News grid:21 */
.MES21 {
color: var(--clr-11876);
border-width: 0 1px;
border-style: solid;
border-color: transparent var(--clr-11879);
 }
.MES21 {
color: var(--clr-11876);
border-width: 0 1px;
border-style: solid;
border-color: transparent var(--clr-11879);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-11878);
 }
 }
cite.MEC21{
color: var(--clr-11876);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-11879);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: #000000;
}
color: #ffffff;
& > article { color: #ffffff;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Secondary:24 */
.MES24 {
color: var(--clr-11878);
border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* White Line:25 */
.MES25 {
padding: 0 0 5px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: #ffffff transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Main Menu:26 */
nav.me-Menu.MES26 {
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: #ffffff;
}
 &:hover > a.MEC26{color: var(--clr-11883);
}
 }
&.horizontal > .menu-item.MEC26 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC26 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC26 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC26 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 10px 32px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC26.active { & > a{ color: var(--clr-11883);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Spacer (Big):27 */
.MES27 {
padding: 10px;

@media #{$medium-up} {
padding: 20px 0;

}
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-11876) transparent transparent transparent;}
& > hr {border-top-style: dashed;}
 }
/* Teacher Panel White:28 */
.MES28 {
background-color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 30px 15px;

}
 }
.MES28 {
background-color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 30px 15px;

}
 }
/* Recent Session Button:30 */
.MES30 {
background-color: var(--clr-11884);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 5px 30px;

 }
/* Header:31 */
.MES31 {
background-color: var(--clr-11878);
color: #ffffff;
 }
.MES31 {
background-color: var(--clr-11878);
color: #ffffff;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: var(--clr-11883);
 }
 }
a.MEC31 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC31{
color: #ffffff;
}
/* Larger (Red Heading):32 */
.MES32 {
font-size: 16.8px;
 }
.MES32 {
font-size: 16.8px;
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: var(--clr-11885);
 }
h1.MEC32 { font-size: 37.5px; }
h2.MEC32 { font-size: 33px; }
h3.MEC32 { font-size: 30px; }
h4.MEC32 { font-size: 27px; }
h5.MEC32 { font-size: 25.5px; }
h6.MEC32 { font-size: 24px; }
 }
cite.MEC32{
font-size: 16.8px;
}
